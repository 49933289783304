import styled from 'styled-components';

export const ContainerValues = styled.div`
  margin-right: 10px;
  display: flex;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-bottom: 10px;
  position: relative;
  button {
    padding: 5px 10px;
    height: 30px;
    padding-right: 20px;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
  }

  .filtroText {
    width: 350px;
    margin-right: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .closed {
    margin-left: -5px;
    right: 0px;
    padding: 5px;
    border-radius: 0px 10px 10px 0px;
    svg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const SectionSettigs = styled.div`
  padding: 24px;
  border-radius: 4px;
  box-shadow: rgb(9 30 66 / 25%) 0px 0px 25px 5px;
  border: 0.0625rem solid rgb(87, 6, 158);

  background-color: rgb(255, 255, 255);
  position: absolute;
  width: 100%;
  top: 40px;
  z-index: 4;
  button {
    margin-top: 10px;
    border-radius: 5px;
  }
`;

export const InputTagSt = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  align-items: center;
  border: 2px solid #ccc;
  min-height: 42px;
`;
