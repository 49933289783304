/* eslint-disable react/no-array-index-key */
import React, { ChangeEvent, useEffect, useState, useContext } from 'react';

import { Radio } from '@atlaskit/radio';
import Button from '@atlaskit/button';
import { Field } from '@atlaskit/form';
import { Tags } from '../../MyComponents/Tags';
import { InputTagSt } from '../../styles';
import { InpuTypeString } from './styles';
import Macro from '~/components/Macro';
import { FilterContext } from '~/context/filter';
import { useFiltro } from '../../FiltroContext';

const options = [
  {
    description: 'Igual à',
    cod_condicao: 0,
  },
  {
    description: 'Maior ou igual à',
    cod_condicao: 1,
  },
  {
    description: 'Menor ou igual à',
    cod_condicao: 2,
  },
  {
    description: 'Diferente de',
    cod_condicao: 3,
  },
  {
    description: 'Entre',
    cod_condicao: 6,
  },
  {
    description: 'Preenchido',
    cod_condicao: 7,
  },
  {
    description: 'Não Preenchido',
    cod_condicao: 8,
  },
];

const optionsLookup = [
  {
    description: 'Igual à',
    cod_condicao: 0,
  },
  {
    description: 'Diferente de',
    cod_condicao: 3,
  },
  {
    description: 'Preenchido',
    cod_condicao: 7,
  },
  {
    description: 'Não Preenchido',
    cod_condicao: 8,
  },
];

const TypeInteger: React.FC = () => {
  // Condições
  const igualA = 0;
  const diferenteDe = 3;
  const entre = 6;
  const preenchido = 7;
  const naoPreenchido = 8;

  const [isMulti, setMulti] = useState<boolean>(false);
  const [isInfo, setInfo] = useState<boolean>(false);
  const { RemoveFilterEmptyByIndex } = useContext(FilterContext);
  const { handleSetNewValue, searchOnClick, isFilterBlock, filter } =
    useFiltro();

  const [value, setValue] = useState<any>(
    Array.isArray(filter.des_valor) ? filter.des_valor[0] : filter.des_valor,
  );
  const [value2, setValue2] = useState<number>(
    Array.isArray(filter.des_valor) ? filter.des_valor[1] : filter.des_valor,
  );
  const [tags, setTags] = useState<string[]>(
    Array.isArray(filter.des_valor) ? filter.des_valor : [],
  );
  const [condition, setCondition] = useState(filter.condicao);

  useEffect(() => {
    handleSetValueFiltro();
  }, [searchOnClick]);

  useEffect(() => {
    if (condition === entre) {
      setMulti(true);
    } else {
      setMulti(false);
    }
  }, [condition]);

  useEffect(() => {
    if (filter.cod_tipo_info > 0) {
      setInfo(true);
    }
  }, [filter.cod_tipo_info]);

  const handleChangeCondition = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCondition(Number(e.target.value));
  };

  const handleSetValueFiltro = () => {
    if (ValidateInputs()) {
      if (!isFilterBlock) RemoveFilterEmptyByIndex(filter.index);
      return;
    }
    const Value = handleDesValor();
    handleSetNewValue({
      index: filter.index,
      des_nome: filter.des_nome,
      nome_bd: filter.nome_bd,
      condicao: condition,
      des_valor: Value,
      cod_tipo_dados: filter.cod_tipo_dados,
      cod_tipo_info: filter.cod_tipo_info,
      cod_campo: filter.cod_campo,
      isBlock: isFilterBlock,
      expressao_validacao: filter.expressao_validacao,
      listagem_info: filter.listagem_info,
      tipo_expressao: filter.tipo_expressao,
    });
  };
  const ValidateInputs = () => {
    if (condition === naoPreenchido || condition === preenchido) {
      return false;
    }
    if (filter.cod_tipo_info > 0) {
      return value === undefined;
    }
    if (condition === igualA || condition === diferenteDe) {
      if (tags.length < 1) {
        return true;
      }
    } else if (
      !(condition === entre ? value >= 0 && value2 >= 0 : value >= 0)
    ) {
      return true;
    }
    return false;
  };

  const handleDesValor = () => {
    type ConditionMappings = {
      [key: number]: undefined | string[] | number[];
    };

    const conditionMappings: ConditionMappings = {
      [preenchido]: undefined,
      [naoPreenchido]: undefined,
      [igualA]: tags,
      [diferenteDe]: tags,
      [entre]: [value, value2],
    };

    if (filter.cod_tipo_info > 0) {
      return value;
    }

    return conditionMappings[condition] || value;
  };

  const handleTags = (newTags: any) => {
    setTags(newTags);
  };
  return (
    <div>
      {!isInfo
        ? options.map((opt, i) => (
            <div key={`${filter.index} ${i}`}>
              <Radio
                label={opt.description}
                name={`${filter.index} ${opt.description}`}
                testId="radio-default"
                value={opt.cod_condicao.toString()}
                isChecked={condition === opt.cod_condicao}
                onChange={handleChangeCondition}
                placeholder="Digite para pesquisar..."
              />
            </div>
          ))
        : optionsLookup.map((opt, i) => (
            <div key={`${filter.index} ${i}`}>
              <Radio
                label={opt.description}
                name={`${filter.index} ${opt.description}`}
                testId="radio-default"
                value={opt.cod_condicao.toString()}
                isChecked={condition === opt.cod_condicao}
                onChange={handleChangeCondition}
                placeholder="Digite para pesquisar..."
              />
            </div>
          ))}
      <div
        className="my-2"
        style={{
          display:
            condition === naoPreenchido || condition === preenchido
              ? 'none'
              : 'block',
        }}
      >
        {!isInfo ? (
          <>
            <div
              style={{
                display:
                  condition === igualA || condition === diferenteDe
                    ? 'block'
                    : 'none',
              }}
            >
              <Field
                label="Pressione Enter para Confirmar"
                name="Pressione Enter para Confirmar"
              >
                {() => (
                  <InputTagSt>
                    <Tags
                      tags={tags}
                      onChange={(e: any) => {
                        setValue('');
                        handleTags(e);
                      }}
                      inputProps={{
                        type: 'number',
                        maxLength: 2147483647,
                      }}
                    />
                  </InputTagSt>
                )}
              </Field>
            </div>

            <InpuTypeString
              style={{
                display:
                  condition === igualA || condition === diferenteDe
                    ? 'none'
                    : 'block',
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setTags([]);

                if (
                  Number(e.target.value) > 2147483647 ||
                  Number(e.target.value) < -2147483647
                ) {
                  return;
                }
                setValue(parseInt(e.target.value, 10));
              }}
              id={filter.index}
              value={value}
              type="number"
              step="1"
              min={0}
            />
          </>
        ) : (
          <div
            style={{
              display: condition === 8 || condition === 7 ? 'none' : 'block',
            }}
          >
            <Macro
              tipoInfo={filter.cod_tipo_info}
              defaultValue={value}
              onChange={(e) => {
                setTags([]);

                setValue(e.value);
              }}
            />
          </div>
        )}
      </div>

      <div className={`my-2 ${isMulti ? 'd-block' : 'd-none'}`}>
        {!isInfo ? (
          <InpuTypeString
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (
                Number(e.target.value) > 2147483647 ||
                Number(e.target.value) < -2147483647
              ) {
                return;
              }

              setValue2(parseInt(e.target.value, 10));
            }}
            value={value2}
            type="number"
            step="1"
            className="my-2"
            min={0}
            placeholder="Digite para pesquisar..."
          />
        ) : (
          <Macro
            tipoInfo={filter.cod_tipo_info}
            defaultValue={value2}
            onChange={(e) => {
              setValue2(e.value);
            }}
          />
        )}
      </div>
      <Button
        appearance={ValidateInputs() ? 'default' : 'primary'}
        type="button"
        onClick={handleSetValueFiltro}
        isDisabled={ValidateInputs()}
      >
        Atualizar
      </Button>
    </div>
  );
};

export default TypeInteger;
