/* eslint-disable react/no-array-index-key */
import React, { ChangeEvent, useEffect, useState, useContext } from 'react';

import { Radio } from '@atlaskit/radio';
import Button from '@atlaskit/button';

import { InpuTypeString } from './styles';
import { FilterContext } from '~/context/filter';
import { useFiltro } from '../../FiltroContext';
import { transformAsCurrency } from '~/utils/functions';
import { moedaFormatada } from './formatMask';

const options = [
  {
    description: 'Igual à',
    cod_condicao: 0,
  },
  {
    description: 'Maior ou igual à',
    cod_condicao: 1,
  },
  {
    description: 'Maior que',
    cod_condicao: 9,
  },
  {
    description: 'Menor ou igual à',
    cod_condicao: 2,
  },
  {
    description: 'Menor que',
    cod_condicao: 10,
  },
  {
    description: 'Diferente de',
    cod_condicao: 3,
  },
  {
    description: 'Entre',
    cod_condicao: 6,
  },
  {
    description: 'Preenchido',
    cod_condicao: 7,
  },
  {
    description: 'Não Preenchido',
    cod_condicao: 8,
  },
];

const TypeDecimal: React.FC = () => {
  // Condições
  const entre = 6;
  const preenchido = 7;
  const naoPreenchido = 8;

  const { searchOnClick, isFilterBlock, handleSetNewValue, filter } =
    useFiltro();

  const [isMulti, setMulti] = useState<boolean>(false);
  const [Mask, setMask] = useState<string>('');
  const [value, setValue] = useState<number | string>(
    Array.isArray(filter.des_valor) ? filter.des_valor[0] : filter.des_valor,
  );
  const [value2, setValue2] = useState<number | string>(
    Array.isArray(filter.des_valor) ? filter.des_valor[1] : filter.des_valor,
  );
  const [condition, setCondition] = useState(filter.condicao);
  const { RemoveFilterEmptyByIndex } = useContext(FilterContext);

  useEffect(() => {
    handleSetValueFiltro();
  }, [searchOnClick]);

  useEffect(() => {
    if (filter.tipo_expressao === 1) {
      if (filter.listagem_info) {
        const maskExp = JSON.parse(filter.listagem_info);
        setMask(maskExp[0].valor);
      }
    }
  }, [
    filter.tipo_expressao,
    filter.listagem_info,
    value,
    filter.expressao_validacao,
    filter.condicao,
  ]);

  useEffect(() => {
    if (condition === entre) {
      setMulti(true);
    } else {
      setMulti(false);
    }
  }, [condition]);

  const handleDesValor = () => {
    if (condition === preenchido || condition === naoPreenchido) {
      return undefined;
    }
    if (condition === entre) {
      return [transformAsCurrency(value), transformAsCurrency(value2)];
    }
    return transformAsCurrency(value);
  };

  const handleSetValueFiltro = () => {
    if (ValidateInputs()) {
      if (!isFilterBlock) RemoveFilterEmptyByIndex(filter.index);
      return;
    }

    const Value = handleDesValor();
    handleSetNewValue({
      index: filter.index,
      des_nome: filter.des_nome,
      nome_bd: filter.nome_bd,
      condicao: condition,
      des_valor: Value,
      cod_tipo_dados: filter.cod_tipo_dados,
      cod_tipo_info: filter.cod_tipo_info,
      cod_campo: filter.cod_campo,
      isBlock: isFilterBlock,
      expressao_validacao: filter.expressao_validacao,
      listagem_info: filter.listagem_info,
      tipo_expressao: filter.tipo_expressao,
    });
  };

  const ValidateInputs = () => {
    if (condition === naoPreenchido || condition === preenchido) {
      return false;
    }

    let val = value;
    let val2 = value;

    if (value && typeof value !== 'number') {
      val = transformAsCurrency(value);
    }
    if (value2 && typeof value2 !== 'number') {
      val2 = transformAsCurrency(value2);
    }

    if (
      !(condition === entre
        ? Number(val) >= 0 && Number(val2) >= 0
        : Number(val) >= 0 && val !== '' && val2 !== '')
    ) {
      return true;
    }
    return false;
  };

  function onChangeInput(event: ChangeEvent<HTMLInputElement>) {
    const valueInput = event.target.value;
    const ValidarCarecteresEspeciais = /\W|_/;

    let valor = '';
    if (Mask) {
      for (let i = 0; i < valueInput.length; i++) {
        const splitMask = Mask.split(',');

        if (splitMask[1]) {
          if (splitMask[1].length === 3) {
            const serializedValue = moedaFormatada(valueInput, 3);
            setValue(serializedValue);
            return;
          }

          if (splitMask[1].length === 2) {
            const serializedValue = moedaFormatada(valueInput, 2);
            setValue(serializedValue);
            return;
          }
        }

        if (ValidarCarecteresEspeciais.test(Mask[i])) {
          valor += Mask[i];
          valor += valueInput[i].replace(/[^0-9]/g, '') || '';
        } else {
          valor += valueInput[i].replace(/[^0-9]/g, '') || '';
        }
      }

      setValue(valor);
      return;
    }
    setValue(valueInput);
  }
  function onChangeInput2(event: ChangeEvent<HTMLInputElement>) {
    const valueInput = event.target.value;
    const ValidarCarecteresEspeciais = /\W|_/;

    const padraoMonetario = /^R?\$?\s*\d{1,3}(\.\d{3})*,\d{2}$/;

    let valor = '';
    for (let i = 0; i < valueInput.length; i++) {
      const splitMask = Mask.split(',');
      if (Mask) {
        if (splitMask[1]) {
          if (splitMask[1].length === 3) {
            const serializedValue = moedaFormatada(valueInput, 3);
            setValue2(serializedValue);
            return;
          }
        }

        if (ValidarCarecteresEspeciais.test(Mask[i])) {
          if (padraoMonetario.test(Mask)) {
            const serializedValue = moedaFormatada(valueInput, 2);
            setValue2(serializedValue);
            return;
          }
        }

        if (ValidarCarecteresEspeciais.test(Mask[i])) {
          valor += Mask[i];
          valor += valueInput[i].replace(/[^0-9]/g, '') || '';
        } else {
          valor += valueInput[i].replace(/[^0-9]/g, '') || '';
        }

        setValue2(valor);
        return;
      }
      setValue2(valueInput);
      return;
    }

    setValue2(valueInput);
  }

  return (
    <div>
      {options.map((opt, i) => (
        <div key={`${filter.index} ${i}`}>
          <Radio
            label={opt.description}
            name={`${filter.index} ${opt.description}`}
            testId="radio-default"
            value={opt.cod_condicao.toString()}
            isChecked={condition === opt.cod_condicao}
            onChange={(e) => {
              setCondition(Number(e.target.value));
            }}
            placeholder="Digite para pesquisar..."
          />
        </div>
      ))}
      <div
        style={{
          display:
            condition === naoPreenchido || condition === preenchido
              ? 'none'
              : 'block',
        }}
      >
        <InpuTypeString
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChangeInput(e);
          }}
          value={value}
          id={filter.index}
          type="string"
          step="0.1"
          placeholder="Digite para pesquisar..."
        />

        <div className={`my-2 ${isMulti ? 'd-block' : 'd-none'}`}>
          e
          <InpuTypeString
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInput2(e)}
            value={value2}
            type="string"
            className="my-2"
            placeholder="Digite para pesquisar..."
          />
        </div>
      </div>

      <Button
        appearance={ValidateInputs() ? 'default' : 'primary'}
        isDisabled={ValidateInputs()}
        type="button"
        onClick={handleSetValueFiltro}
      >
        Atualizar
      </Button>
    </div>
  );
};

export default TypeDecimal;
