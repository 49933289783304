import React, { useEffect, useState } from 'react';
import { ContainerValues, InputTags } from './styles';
import { TagsProps } from './types';
import { v4 } from 'uuid';

export const Tags: React.FC<TagsProps> = ({ tags, onChange, inputProps }) => {
  const { maxLength, type, onChangeInput, value } = inputProps;

  const [tagInputValue, setTagInputValue] = useState<string>('');
  const [tagsArray, setTagsArray] = useState<any>([]);

  useEffect(() => {
    setTagsArray(tags);
  }, [tags]);

  useEffect(() => {
    if (value) setTagInputValue(value);
  }, [value]);

  const handleAddTag = (event: any) => {
    if (event.key === 'Enter') {
      const tagArrayNew = [...tagsArray, tagInputValue.trim()];
      setTagsArray(tagArrayNew);
      onChange(tagArrayNew);
      setTagInputValue('');
    }
  };

  const removeTag = (indexTag: number) => {
    const newArray: any = tagsArray.filter(
      (_: string, index: number) => index !== indexTag,
    );
    setTagsArray(newArray);
    onChange(newArray);
  };

  const focusFilterInput = async () => {
    const input = await document.getElementById('inputTag');
    input?.focus();
  };

  return (
    <ContainerValues>
      <div className="tags" onClick={() => focusFilterInput()}>
        {tagsArray.length > 0 &&
          tagsArray.map((tag: string, index: number) => (
            <div
              key={v4()}
              className="containerTags"
              onClick={() => focusFilterInput()}
            >
              <span className="react-tags">{tag}</span>
              <span
                className="react-tag-remove"
                onClick={() => removeTag(index)}
              >
                ✖
              </span>
            </div>
          ))}
      </div>

      <InputTags
        id="inputTag"
        type={type}
        onChange={(event) => {
          if (onChangeInput !== undefined) {
            onChangeInput(event);
          }

          if (type === 'number') {
            if (
              Number(event.target.value) > 2147483647 ||
              Number(event.target.value) < -2147483647
            ) {
              return;
            }
          }

          setTagInputValue(event.target.value);
        }}
        value={tagInputValue}
        maxLength={maxLength}
        onKeyPress={(event) => {
          if (tagInputValue.trim() !== '') handleAddTag(event);
        }}
        placeholder="Digite para pesquisar..."
      />
    </ContainerValues>
  );
};
